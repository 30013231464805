<template>
  <div class="order-detail">
    <baseContainer back page="事项详情">
      <div class="order-info">
        <div class="split-title"><span>基本信息</span></div>
        <div class="order-user-info">
          <div class="info-left-box">
            <div class="info-detail">
              <div>
                <span class="info-detail-title">{{ orderDetail.title }}</span> <span class="info-detail-status status-org">{{ orderDetail.statusName }}</span>
              </div>
              <div>
                <i class="iconfont"></i><span>{{ orderDetail.areaLocation }}</span>
              </div>
              <div class="info-split-detail">
                <span><strong>事项编号：</strong>{{ orderDetail.orderId }}</span>
                <span><strong>上报部门：</strong>{{ orderDetail.orderCreateDept }}</span>
                <span><strong>经办部门：</strong>{{ orderDetail.orderBelongDept }}</span>
              </div>
              <div class="info-split-detail">
                <span><strong>上报人：</strong>{{ orderDetail.nextUserName }}</span>
                <span><strong>电话号码： </strong>{{ orderDetail.reporterMobile }}</span>
                <span><strong>上报时间：</strong>{{ formatDate(orderDetail.reportTime) }}</span>
                <!-- <span><strong>上报时间：</strong>{{ formatDate(orderDetail.reportTime) }}</span> -->
              </div>
              <div class="info-split-detail">
                <span><strong>事项类型： </strong>{{ orderDetail.orderClassficationName }}</span>
                <span><strong>问题分类：</strong>{{ orderDetail.matterClassficationName }}</span>
                <span><strong>问题性质：</strong>{{ orderDetail.matterPropertyName }}</span>
              </div>
              <div class="info-split-detail">
                <span><strong>当前操作人： </strong>{{ orderDetail.nextUserName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </baseContainer>
    <div class="order-tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
        <el-tab-pane label="事项信息" name="first">
          <div class="tab-detail">
            <div class="border">
              <strong>问题描述</strong>
              <p>
                {{ orderDetail.des }}
              </p>
            </div>
            <div class="border">
              <strong>建议及整改期望</strong>
              <p>
                {{ orderDetail.advice }}
              </p>
            </div>
            <div class="border">
              <strong>问题发生区域</strong>
              <p>{{ orderDetail.areaLocation }}</p>
            </div>
            <div class="border">
              <strong>图片</strong>
              <div class="img-box">
                <vviewer :images="beforeImg" />
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="流程记录" name="second">
          <div class="tab-block">
            <el-timeline>
              <el-timeline-item v-for="(item, index) in processing" :key="index" :timestamp="formatDate(item.updateTime)" placement="top">
                <el-card>
                  <div class="card-info">
                    <div class="flex-between">
                      <div class="info-left">
                        <el-avatar :size="40" :src="item.operateUserAvatar"></el-avatar>
                        <div class="user-info">
                          <strong>{{ item.operateUserName }}</strong>
                          <p>{{ item.department }}</p>
                        </div>
                      </div>
                      <div
                        class="status"
                        :class="{
                          'status-fail': item.operateTypeName === '暂不受理',
                          'status-back': item.operateTypeName === '回退给' || item.operateTypeName === '撤回',
                          'status-accpeted': item.operateTypeName === '处理完成' || item.operateTypeName === '验收完成',
                          'status-wait':
                            item.operateTypeName === '交办给' || item.operateTypeName === '上报给' || item.operateTypeName === '保存草稿' || item.operateTypeName === '创建'
                        }"
                      >
                        {{ item.operateTypeName.replace(/给/, '') }}
                      </div>
                    </div>
                    <div class="handle-info">
                      {{ item.operateTypeName }} {{ item.assignedUserName }} <span v-if="item.assignedUserMobile">({{ item.assignedUserMobile }})</span><br />
                      {{ item.operateDesc }}
                    </div>
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-tab-pane>
        <el-tab-pane label="处理记录" name="third" v-if="feReceiptList.length > 0">
          <div class="tab-detail" v-for="(feReceipt, index) in feReceiptList" :key="feReceipt.id">
            <div class="border">
              <strong>处理信息</strong>
              <p>
                <span
                  >处理人：{{ feReceipt.createName }}<i v-if="feReceipt.createMobile">({{ feReceipt.createMobile }})</i></span
                >
              </p>
              <p>
                <span>处理时间：{{ formatDate(feReceipt.createTime) }}</span>
              </p>
            </div>
            <div class="border">
              <strong>处理描述</strong>
              <p>{{ feReceipt.receiptDesc }}</p>
            </div>
            <div class="border" v-if="feReceipt.receiptPictureUrl">
              <strong>图片</strong>
              <div class="img-box">
                <vviewer :images="afterImg[index]" />
                <!-- <img :src="feReceipt.receiptPictureUrl" :onerror="defaultImg" /> -->
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import baseContainer from '@/components/container/base-container';
import { TABLE } from '/app.config';
import { table } from './mixin';
import '@/common/style/detail.scss';
import vviewer from '@/components/viewer/viewer';
export default {
  name: 'workingDetail',
  mixins: [table],
  props: ['id'],
  data() {
    return {
      orderDetail: {},
      feReceiptList: [],
      processing: [],
      drawer: false,
      searchText: '',
      multipleSelection: [],
      pagination: {
        total: 0,
        pageSize: TABLE.page_sizes[0],
        currentPage: 1
      },
      orderType: '',
      problemType: '',
      status: '',
      formLabelWidth: '80px',
      scoreForm: {
        score: '',
        desc: '',
        isBest: true
      },
      scoreRules: {
        score: [{ required: true, message: '请输入积分', trigger: 'blur' }]
      },
      dialogSetFormVisible: false,
      dialogSetShowVisible: false,
      isShow: true,
      activeName: 'first',
      detailInfo: {},
      beforeImg: [],
      afterImg: []
    };
  },
  activated() {
    this.beforeImg = [];
    this.getDetail();
    this.activeName = 'first';
  },
  computed: {
    defaultImg() {
      return `this.src="${require('@/assets/img/default.png')}"`;
    }
  },

  methods: {
    async getDetail() {
      const { data } = await this.$apis.workOrder.getDetail(this.id);
      this.orderDetail = data;
      this.beforeImg.push(data.pictureUrl);
      this.processing = data.records;
      this.feReceiptList = data.feReceiptList;
      if (data.feReceiptList.length > 0) {
        this.afterImg = [];
        this.feReceiptList = data.feReceiptList;
        this.feReceiptList.forEach((item, index) => {
          this.afterImg[index] = [item.receiptPictureUrl];
        });
      }
      // if (this.feReceipt && this.feReceipt.receiptPictureUrl) {
      //   this.afterImg.push(this.feReceipt.receiptPictureUrl);
      // }
    },
    cancel(formVisible) {
      this[formVisible] = false;
    },
    //设置积分
    setScore(formVisible) {
      this[formVisible] = true;
    },
    //设置积分提交
    submitScoreForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submitShowForm() {},
    handleClick(tab, event) {
      console.log(tab, event);
    }
  },
  watch: {
    searchText() {
      this.loadData();
    }
  },
  components: {
    baseContainer,
    vviewer
  }
};
</script>
<style lang="scss" scoped>
.flex-between {
  display: flex;
  justify-content: space-between;
}
.status {
  padding: 0 10px;
  border-radius: 2px;
  display: inline-block;
  height: 30px;
  line-height: 30px;
}
.status-wait {
  color: #f39800;
  background: rgba(243, 152, 0, 0.1);
}
.status-accpeted {
  color: #19a572;
  background: rgba(25, 165, 114, 0.1);
}
.ft-accpeted {
  color: #19a572 !important;
}
.status-fail {
  color: #ea3837;
  background: rgba(234, 56, 55, 0.1);
}
.status-back {
  color: #909399;
  background: rgba(144, 147, 153, 0.1);
}
.tab-detail {
  padding: 0;
  p {
    white-space: pre-wrap;
  }
}
.order-info {
  padding-bottom: 0;
}
.split-title {
  margin-top: -10px;
}
.order-user-info {
  margin-top: 30px;
  padding-left: 20px;
}
.info-detail {
  .info-detail-title {
    font-size: 18px;
  }
  .info-detail-status {
    border-radius: 4px;
    padding: 0 10px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
  }
  .status-org {
    color: #f39800;
    background: rgba(243, 152, 0, 0.1);
  }
  .info-split-detail {
    margin-top: 20px;
    display: flex;
    span {
      font-size: 14px;
      display: block;
      width: 33%;
      color: #121212;
    }
    strong {
      color: #5a5a5a;
      font-weight: normal;
    }
  }
}
</style>